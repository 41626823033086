
import { Component, Watch, Vue } from 'vue-property-decorator';
import ProjectContent from '@/components/views/NewProjectView/ProjectContent.vue';
import ProjectViewSidebar from '@/components/global/sidebar/ProjectViewSidebar.vue';
import { RouteNames } from '@/enums/routes/RouteNames';
import ProjectRepository from '@/repositories/ProjectRepository';
import Project from '@/models/Project';
import ProjectLog from '@/models/ProjectLog';
import CMSUser from '@/models/CMSUser';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Label from '@/models/Label';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import NewProjectTopBar from '../components/views/project/NewProjectTopBar.vue';
import Client from '@/models/Client';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { LocalStorageService } from '@/services/LocalStorageService';
import { SessionStorageService as ss } from '@/services/SessionStorageService';
import { SessionStorageKeyNames } from '@/enums/global/SessionStorageCommonEndpoints';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { RouteConfig } from 'vue-router';
import PriceManipulationType from '@/models/PriceManipulationType';
import ProjectSearchRepository from '@/repositories/ProjectSearchRepository';
import { setFirstProjectOfferAsSelectedIfNoneIsSelected } from '@/helpers/NewProject/NewProjectHelper';
import { UserRepository } from '@/repositories/UserRepository';
import OfferRepository from '@/repositories/OfferRepository';
import Offer from '@/models/Offer';

@Component({
    name: 'NewProjectView',
    components: { ProjectContent, ProjectViewSidebar, NewProjectTopBar },
})
export default class NewProjectView extends Vue {
    private client: Client | null = null;
    private beforeRoute: RouteConfig | {} = {};
    private selectedOffer: Offer | null = null;
    private createCustomOrder = false;
    private isProjectFetching = false;

    private get project() {
        return ProjectRepository.getProjectById(this.$route.params.id);
    }

    private get basicProjectData() {
        return ProjectSearchRepository.getById(this.$route.params.id);
    }

    private get projectId() {
        if (this.$route == null) {
            return null;
        }

        return this.$route.params.id;
    }

    private get lockEditing() {
        if (this.project) {
            return (
                (String(this.project.userGroupId) !== String(this.$store.getters['jwtData/currentUserGroup']) &&
                    !(
                        this.project.state === 'request' &&
                        String(this.$store.getters['jwtData/currentUserGroup']) === '1'
                    )) ||
                this.isTechnologistEditingAnotherUsersOffer
            );
        }

        return true;
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get basicStateSpecificId() {
        if (!this.basicProjectData) {
            return '';
        }

        if (this.basicProjectData.state !== 'order') {
            return this.basicProjectData.id;
        }
        return this.basicProjectData?.orderNumber ?? this.basicProjectData?.offerName ?? '';
    }

    private get canSeeOrders() {
        if (this.project === null) {
            return false;
        }

        return this.project.state === 'order';
    }

    private get showProjectContent() {
        return (
            !!this.project ||
            // (this.basicProjectData && (this.$route.query.initialTab === '0' || this.$route.query.initialTab === '2'))
            (this.basicProjectData && this.$route.query.initialTab === '0')
        );
    }

    private get isCurrentUserTechnologist() {
        return UserRepository.isCurrentUserTechnologist();
    }

    private get isTechnologistEditingAnotherUsersOffer() {
        if (!this.isCurrentUserTechnologist) {
            return false;
        }

        if (!this.project) {
            return false;
        }

        return this.project.madeByUser.id !== this.$store.getters['jwtData/userId'];
    }

    @Watch('projectId')
    private async handleProjectChange(newVal: any) {
        await this.setData();
    }

    private async getBasicProjectDataRequest() {
        switch (this.$route.query.initialTab) {
            case '0':
                if (!this.basicProjectData) {
                    return Project.getById(this.$route.params.id, 'basic');
                }
                break;
            case '1':
                return Project.getById(this.$route.params.id, 'leads');
            case '2':
                return Project.getById(this.$route.params.id, 'offers');
            // return;
            case '3':
                return Project.getById(this.$route.params.id, 'orders');
            case '4':
                return Project.getById(this.$route.params.id, 'payments');
        }
    }

    private getProjectFetchingRequests() {
        const requests: Array<Promise<any>> = [];

        if (this.$route.query.initialTab) {
            requests.push(this.getBasicProjectDataRequest());
        }

        if (this.$route.query.initialTab !== '2') {
            requests.push(this.fetchProjectAndUpdateOffer());
        }

        return requests;
    }

    private async fetchProjectAndUpdateOffer() {
        await Project.fetchDataForProjectView(this.$route.params.id);
        this.updateSelectedOffer();
    }

    private async conditionallyFetchProjectData(isInitial: boolean) {
        if (isInitial && this.$route.params?.tab !== 'request') {
            const requests = this.getProjectFetchingRequests();

            await Promise.all(requests);

            if (this.$route.query.initialTab === '2') {
                await this.fetchProjectAndUpdateOffer();
                await setFirstProjectOfferAsSelectedIfNoneIsSelected(this.$route.params.id);
            }
        } else {
            await this.fetchProjectAndUpdateOffer();
        }
    }

    private async setData(isInitial: boolean = false) {
        try {
            await this.conditionallyFetchProjectData(isInitial);

            this.client = this.project!.projectClient;

            // Check row numbers in offers tab in case we have duplicate numbers
            EventBus.$emit(EventBusEvents.checkRowNumbers);

            // Get offer id
            const offerId = this.selectedOffer?.id;
            await Promise.all([
                ProjectLog.getById(this.$route.params.id),
                CMSUser.getAll(),
                Label.fetchAvailableLabels(this.$route.params.id),
                PriceManipulationType.getAll(),
                // offerId ? OfferNote.getById(offerId) : Promise.resolve(),
                // offerId ? OfferNote.getAll() : Promise.resolve(),
            ]);
        } catch {
            this.$notification.error({
                message: 'Error',
                description: 'Error fetching project data',
            });
        }
    }

    private updateSelectedOffer() {
        if (this.projectId == null) {
            return;
        }

        this.selectedOffer = OfferRepository.getSelectedOffer(this.projectId);
    }

    private changeUserGroup(value: string) {
        const allowedUserGroupIds = this.$store.getters['jwtData/allowedUserGroups'].map((id: number) =>
            String(id)
        ) as string[];
        if (!value || value === this.currentUserGroup?.id || !allowedUserGroupIds.includes(value)) {
            return;
        }
        LocalStorageService.remove(ProjectTypes.Lead as string);
        LocalStorageService.remove(ProjectTypes.Offer as string);
        LocalStorageService.remove(ProjectTypes.Order as string);
        LocalStorageService.remove(ProjectTypes.Admin as string);

        Object.keys(sessionStorage).forEach((key: any) => {
            if (!Object.values(SessionStorageKeyNames).includes(key as SessionStorageKeyNames)) {
                ss.remove(key);
            }
        });

        this.$store.dispatch('jwtData/updateUserGroup', value);
    }

    private async onUpdateProject(settings: {
        newOfferId?: string;
        redirectTo?: ProjectTabValues;
        shouldUpdateOffer?: boolean;
        shouldShowPopup?: boolean;
        popupType?: string;
        finalizePriceManipulations?: boolean;
        shouldUpdateRowNumbers?: boolean;
    }) {
        if (this.project == null) {
            return Promise.reject();
        }

        this.isProjectFetching = true;

        try {
            if (this.project.label) {
                await Label.delete(this.project.label.id);
            }

            await Project.getById(this.project.projectId);

            if (this.project == null) {
                return Promise.reject();
            }

            if (settings?.shouldUpdateOffer) {
                EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
            }

            if (settings?.finalizePriceManipulations) {
                EventBus.$emit(EventBusEvents.finishCreatingOfferItemPriceManipulations);
            }

            if (settings?.shouldUpdateRowNumbers) {
                EventBus.$emit(EventBusEvents.updateRowNumbers);
            }

            await ProjectLog.getById(this.project.projectId);

            // Show discount information in case order is successful
            if (settings?.shouldShowPopup) {
                switch (settings?.popupType) {
                    case 'celebration':
                        await this.$nextTick();
                        EventBus.$emit(EventBusEvents.showCelebrationModal);
                }
            }
        } catch (e) {
            this.$notification.error({
                message: 'Error',
                description: 'Error updating project',
            });
        } finally {
            this.isProjectFetching = false;
        }

        return Promise.resolve();
    }

    // This function expects router instance and params object to change
    private updatePathParams() {
        if (!this.isCurrentUserTechnologist) {
            return;
        }

        if (this.$route.query.initialTab === '2') {
            return;
        }

        const newParams = {
            ...this.$route.query, // Keep existing query params
            initialTab: '2',
        };

        this.$router.replace({ query: newParams });
    }

    private beforeRouteEnter(to: any, from: any, next: any) {
        next((vm: any) => {
            // Set the params before leaving the route
            vm.beforeRoute = { ...from };
        });
    }

    private async created() {
        this.updatePathParams();

        if (this.$route && this.$route.params.id == null) {
            this.$router.push({ name: RouteNames.error });
            return;
        }
        if (this.$route && this.$route.query && this.$route.query.ug) {
            this.changeUserGroup(String(this.$route.query.ug));
        } else if (this.$route && this.currentUserGroup?.id) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    ug: this.currentUserGroup?.id,
                },
            });
        }

        await this.setData(true);

        EventBus.$on(EventBusEvents.updateProject, this.onUpdateProject);
        // this event is legacy, but still used in some methods
        EventBus.$on(EventBusEvents.fetchProjectFromRepository, this.onUpdateProject);
        EventBus.$on(EventBusEvents.fetchSelectedOfferFromRepository, this.updateSelectedOffer);
    }

    private async beforeDestroy() {
        EventBus.$off(EventBusEvents.updateProject);
        EventBus.$off(EventBusEvents.fetchProjectFromRepository);
        EventBus.$off(EventBusEvents.fetchSelectedOfferFromRepository, this.updateSelectedOffer);
    }
}
